import Vue from "vue";
import VueRouter from "vue-router";

import LoginView from "../views/LoginView";
import {
  isTokenValid
} from "@/services/api/AuthApiService";



Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "PadlerLogin",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "Paddler",
    meta: {
      loginRequired: true
    },
    component: () => import("@/views/DashboardView.vue"),
    redirect: {
      name: "Dashboard"
    },
    children: [{
        path: "",
        name: "Dashboard",
        component: () => import("@/views/MainDashboard")
      },
      {
        path: "/dashboard/wallet",
        name: "Wallet",
        component: () => import("@/views/wallet/WalletDashboard.vue")
      },
      {
        path: "/dashboard/update-account",
        name: "UpdateAccount",
        component: () => import("@/views/wallet/UpdateAccount"),
      },
      {
        path: "/dashboard/analytics",
        name: "Analytics",
        component: () => import("@/views/analytics/AnalyticsDashboard.vue")
      },
      {
        path: "/verification",
        name: "transportCompanyVerify",
        component: () => import("@/views/VerifyCompanyView.vue"),
      },
      {
        path: "/verify-insurance",
        name: "InsuranceProviderVerify",
        component: () => import("@/views/VerifyInsuranceProviderView.vue"),
      },
      {
        path: "/water-transport-operator",
        name: "WaterTransportOperator",
        component: () => import("@/views/VerifyWaterTransportOperatorView.vue"),
      },
      {
        path: "/corporate-agent",
        name: "CorporateAgent",
        component: () => import("@/views/VerifyCorporateAgentView")
      },
      {
        path: "/developers",
        name: "Developers",
        component: () => import("@/views/product/developer/VerifyDeveloperView.vue")
      },
      {
        path: "/mobile-agent",
        name: "MobileAgent",
        component: () => import("@/views/VerifyMobileAgentView")
      },
      {
        path: "/one-way-trips",
        name: "OneWayTrips",
        component: () => import("@/views/OneWayBooking/SelectBus")
      },
      {
        path: "/one-way-search",
        name: "OneWaySearch",
        component: () => import("@/views/OneWayBooking/OneWaySearch")
      },
      {
        path: "/select-seat",
        name: "OneWaySelectSeats",
        component: () => import("@/views/OneWayBooking/SelectSeats")
      },
      {
        path: "/one-way-passengers-details",
        name: "OneWayInputDetails",
        component: () => import("@/views/OneWayBooking/InputDetails")
      },
      {
        path: "/one-way-check-out",
        name: "OneWayCheckout",
        component: () => import("@/views/OneWayBooking/CheckOut")
      },
      {
        path: "/corporate-organization",
        name: "CorporateOrganization",
        component: () => import("@/views/VerifyCorporateOrganizationView.vue")
      },
      {
        path: "/payment-log",
        name: "PaymentDashboard",
        component: () => import("@/views/payment/PaymentDashboard.vue")
      },
      {
        path: "/support",
        name: "SupportDashboard",
        component: () => import("@/views/support/SupportDashboard")
      },
      {
        path: "/bookings",
        name: "Bookings",
        component: () => import("../views/booking/AllBooking.vue")
      },
      {
        path: "/logs",
        name: "Logs",
        component: () => import("../views/log/AllLogs.vue")
      },
      {
        path: "/view-a-booking",
        name: "ViewABooking",
        component: () => import("../views/booking/SingleBooking.vue")
      },
      {
        path: "/all-paddlers",
        name: "AllPaddlers",
        component: () => import("@/views/paddler/AllPaddlers.vue")
      },
      {
        path: "/view-paddler",
        name: "ViewPaddler",
        component: () => import("@/views/paddler/ViewPaddler.vue")
      },

      {
        path: "/add-paddler",
        name: "AddPaddler",
        component: () => import("@/views/paddler/AddPaddler")
      },
      {
        path: "/edit-paddler/:staffId",
        name: "EditPaddler",
        component: () => import("@/views/paddler/AddPaddler")
      },

      {
        path: "/add-unit",
        name: "AddUnit",
        component: () => import("@/views/paddler/unit/AddUnit")
      },
      {
        path: "/all-units",
        name: "AllUnits",
        component: () => import("@/views/paddler/unit/AllUnits")
      },
      {
        path: "/view-units",
        name: "ViewUnits",
        component: () => import("@/views/paddler/unit/ViewUnits.vue")
      },
      {
        path: "/view-profile",
        name: "ViewProfile",
        component: () => import("@/components/reuseables/ViewProfile.vue")
      },
      {
        path: "/view-departments",
        name: "ViewDepartments",
        component: () => import("@/views/paddler/departments/ViewDepartments.vue")
      },
      {
        path: "/update-unit",
        name: "UpdateUnit",
        component: () => import("@/views/paddler/unit/UpdateUnit")
      },
      {
        path: "/all-departments",
        name: "AllDepartments",
        component: () => import("@/views/paddler/departments/AllDepartments")
      },
      {
        path: "/new-department",
        name: "AddDepartment",
        component: () => import("@/views/paddler/departments/AddDepartment")
      },
      {
        path: "/update-department",
        name: "UpdateDepartment",
        component: () => import("@/views/paddler/departments/UpdateDepartment")
      },
      {
        path: "/marketing-dept",
        name: "MarketingDept",
        component: () => import("@/views/marketing_dept/MarketingDept.vue"),
        redirect: {
          name: "Adverts"
        },
        children: [{
            path: "/blog-post",
            name: "AllBlogPost",
            component: () => import("@/views/marketing_dept/blog/AllBlogPostView.vue")
          },
          {
            path: "/blog-post/new",
            name: "NewBlogPost",
            component: () => import("@/views/marketing_dept/blog/AddBlogPostView.vue")
          },
          {
            path: "/blog-post/edit/:title",
            name: "EditBlogPost",
            component: () => import("@/views/marketing_dept/blog/AddBlogPostView.vue")
          },
          {
            path: "/news-letter",
            name: "NewsLetter",
            component: () => import("@/views/support/NewsLetter.vue")
          },
          {
            path: "/discounts",
            name: "Discounts",
            component: () => import("@/views/marketing_dept/promotion/AllDiscountView.vue")
          },
          {
            path: "/discount/create",
            name: "CreateDiscount",
            component: () => import("@/views/marketing_dept/promotion/AddDiscountView.vue")
          },
          {
            path: "/advertisements",
            name: "Adverts",
            component: () => import("../views/marketing_dept/advert/AdvertDashboard.vue")
          }
        ]
      },
      {
        path: "/view-payment",
        name: "ViewPayment",
        component: () => import("@/views/payment/ViewPayment.vue")
      },
      {
        path: "/view-payment-reference",
        name: "ViewPaymentRef",
        component: () => import("@/views/payment/ViewPaymentRef.vue")
      },
      {
        path: "/all-products",
        name: "ProductDashboard",
        component: () => import("@/views/product/ProductDashboard.vue")
      },
      {
        path: "/products-analytics",
        name: "ProductAnalytics",
        component: () => import("@/views/product/ProductAnalyticsDashboard.vue")
      },
      {
        path: "/product-ribs",
        name: "RibsDashboard",
        component: () => import("@/views/product/ribs/RibsDashboard.vue")
      },
      {
        path: "/view-booking",
        name: "ViewBooking",
        component: () => import("@/views/product/ribs/ViewBooking.vue")
      },
      {
        path: "/update-user",
        name: "UpdateUser",
        component: () => import("@/views/product/UpdateUser.vue")
      },
      {
        path: "/product-skip",
        name: "SkipDashboard",
        component: () => import("@/views/product/skip/SkipDashboard.vue")
      },
      {
        path: "/view-user",
        name: "ViewUser",
        component: () => import("@/views/product/ViewUser.vue")
      },
      {
        path: "/pos-dashboard",
        name: "PosView",
        component: () => import("@/components/pos/PosView.vue"),
        redirect: {
          name: "PosDashboard"
        },
        children: [{
            path: "",
            name: "PosDashboard",
            component: () => import("@/components/pos/PosDashboardView.vue")
          },
          {
            path: "/pos-dashboard/:requestId",
            name: "ApprovePos",
            component: () => import("@/components/pos/ApprovePosView.vue")
          }

        ],
      },
      {
        path: "/waitlist",
        name: "Waitlist",
        component: () => import("@/views/WaitListView.vue"),
      },
    ]
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: "smooth"
    };
  },
});
router.beforeEach((to, from, next) => {
  let userData = JSON.parse(sessionStorage.getItem('userData'))
  if (to.meta.loginRequired) {
    if (!userData) {
      next({
        name: 'PadlerLogin'
      })
    } else {
      isTokenValid(userData.exp)
      return next({
        name: "Dashboard"
      })
    }

  }
  next();
})
export default router;