/* eslint-disable no-unused-vars */
// import axios from "axios";
import authHeader from "@/services/api/auth-header";
import {
  data
} from "autoprefixer";
import callEndpoint from "../../services/api/index";
import METHODS from "../../services/api/apiMethods";
import axios from "axios";

let RESOURCE_URL = "";
if (process.env.NODE_ENV === "development") {
  RESOURCE_URL = process.env.VUE_APP_RESOURCE_SERVER_URL;
} else {
  RESOURCE_URL = process.env.VUE_APP_RESOURCE_SERVER_URL;
}
const {
  POST,
  //     PUT,
  PATCH,
  DELETE,
} = METHODS;

export const uploadToCloudinary = async (data) => {

  return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
const getKudaJwtToken = () => {
  return sessionStorage.getItem('kudaJwt')
}

const header = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + getKudaJwtToken(),
}
// https://res.cloudinary.com/demo/image/upload/f_auto,q_auto,e_trim,b_white/bo_1px_solid_gray/docs/rmv_bgd/stuffed.jpg
// Get countries and their states
const COUNTRY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/states";
const CITY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/state/cities";

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};
const paystackAxios = axios.create({
  baseURL: 'https://api.paystack.co',
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_PAYSTACK_LIVE_SECRET_KEY}`,
    'Content-type': 'application/json',
  }
});
export const getKudaAuthToken = async (data) => {
  const url = 'https://kuda-openapi.kuda.com/v2/Account/GetToken'
  return axios.post(url, data, {})
}

export const getAllBanks = async (data, header) => {
  const url = 'https://kuda-openapi.kuda.com/v2/'
  return axios.post(url, data, {
    headers: header
  })
}
export function getPaymentReferences() {
  return paystackAxios.get('/transaction?perPage=400&page=1');
}

export const getAllCountries = async () => {
  return axios.get(COUNTRY_BASE_URL, {
    headers
  });
};
export const getAllStatesByCountry = async (country) => {
  return axios.post(COUNTRY_BASE_URL, country, {
    headers
  });
};

export const getAllCitiesInAState = async (data) => {
  return axios.post(CITY_BASE_URL, data, {
    headers
  });
};

export const getLatLong = async (data) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${data.houseNumber}+${data.streetName}+${data.city},+${data.state},+${data.country}
  &key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
  return axios.get(url);
};


export const onBoardTransportCompany = async (data) => {
  const url = `/api/transport-company/on-boarding`;
  return callEndpoint({
    method: POST,
    url,
    data,
    needsAuth: true
  });
};

export const getCompanyData = async (companyId) => {
  const url = `/api/transport-company/get/${companyId}`;
  return callEndpoint({
    url,
    needsAuth: true
  });
};

export const addTerminal = async (data) => {
  const url = `/api/transport-company/terminal/add`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};
export const getTransportCompanyTerminals = async (id) => {
  const url = `/api/transport-company/terminal/${id}/all`;
  return callEndpoint({
    url
  });
};
export const getTripByTransportCompanyIdAndTripId = async (data) => {
  const url = `/api/trip/get/${data.transportCompanyId}/${data.tripId}`
  return callEndpoint({
    url
  })
};

export const addFleet = async (data, companyId, terminalId) => {
  const url = `/api/fleet/new-fleet/${companyId}/${terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const deleteFleet = async (companyId, fleetId) => {
  const url = `/api/fleet/delete/${companyId}/${fleetId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const addTerminalManager = async (data, companyId, terminalId) => {
  const url = `/api/terminal-manager/new/${companyId}/${terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data,
    needsAuth: true
  });
};

export const getCompanyManagers = async (id) => {
  const url = `/api/terminal-manager/get-all/${id}`;
  return callEndpoint({
    url
  });
};
export const updateTerminal = async (data, companyId, terminalId) => {
  const url = `/api/transport-company/terminal/${companyId}/${terminalId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data,
    needsAuth: true
  });
};

export const deleteTerminal = async (companyId, terminalId) => {
  const url = `/api/transport-company/terminal/${companyId}/${terminalId}`;
  return callEndpoint({
    method: DELETE,
    url,
    needsAuth: true
  });
};

export const addRoute = async (data) => {
  const url = `/api/route/create/${data.transportCompanyId}/${data.departureTerminalId}/${data.destinationTerminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const getAllTransportCompanyRoute = async (id) => {
  const url = `/api/route/get-all/${id}`;
  return callEndpoint({
    url
  });
};

export const deleteRoute = async (data) => {
  const url = `/api/route/delete/${data.transportCompanyId}/${data.routeId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const updateRoute = async (data) => {
  const url = `/api/route/update/${data.transportCompanyId}/${data.routeId}/${data.departureTerminalId}/${data.destinationTerminalId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data
  });
};

export const getRouteById = async (data) => {
  const url = `/api/route/get/${data.transportCompanyId}/${data.routeId}`;
  return callEndpoint({
    url
  });
};

export const updateTerminalManager = async (
  data,
  companyId,
  terminalId,
  managerId
) => {
  const url = `/api/terminal-manager/update/${companyId}/${terminalId}/${managerId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data,
    needsAuth: true
  });
};

export const getManagerById = async (companyId, managerId) => {
  const url = `/api/terminal-manager/get/${companyId}/${managerId}`;
  return callEndpoint({
    url
  });
};

export const getManagerByEmail = async (email) => {
  const url = `/api/terminal-manager/get-by-email/${email}`
  return callEndpoint({
    url
  })
};

export const getBookingAdminByEmail = async (email) => {
  const url = `/api/booking-admin/get-by-email/${email}`
  return callEndpoint({
    url
  })
};
export const getTransportCompanybyName = async (data) => {
  const url = `/api/service-provider/get-by-name`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getAllProducts = async () => {
  const url = `/api/products/get-all`
  return callEndpoint({
    url
  })
}
export const getTripbyId = async (data) => {
  const url = `/api/trip/get-trip-by-id`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getTripByTripId = async (data) =>{
  const url = `/api/aggregated-trips/get-trip-by-schedule-id-or-trip-id`
  return callEndpoint({method:POST,url,data})
}

export const rectifyFailedBookingWithLogId = async (data)=>{
  const url = `/api/booking-bd/rectify/booking-with-log-id`
  return callEndpoint({method:POST,url,data})
}
export const getPendingTripsBySearchQuery = async (data) => {
  const url = `/api/trip/get-all-transport-company-pending-trip-by-search-query`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const reserveSeats = async (data) => {
  const url = `/api/customer-trip/reserve-seat/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}
export const deleteManagerById = async (data) => {
  const url = `/api/terminal-manager/delete/${data.transportCompanyId}/${data.terminalManagerId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const getTerminalById = async (terminalId) => {
  const url = `/api/transport-company/terminal/${terminalId}`;
  return callEndpoint({
    url
  });
};

export const getAllCompanyFleets = async (id) => {
  const url = `/api/fleet/get-all/${id}`;
  return callEndpoint({
    url
  });
};
export const addNewUnit = async (data) => {
  const url = `/api/paddler/unit/new-unit`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getAllPaddlers = async (data) => {
  const url = `/api/paddler/get-all`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const updatePaddler = async (data) => {
  const url = `/api/paddler/update`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}
export const getPaddleByEmail = async (data) => {
  const url = `/api/paddler/get-by-email`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const addNewPaddler = async (data) => {
  const url = `/api/paddler/add-new`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const addNewDepartment = async (data) => {
  const url = `/api/paddler-department/add`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const updateDepartment = async (data) => {
  const url = `/api/paddler-department/update`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const transferDepartment = async (data) => {
  const url = `/api/paddler-department/transfer-department`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const transferPaddler = async (data) =>{
  const url = `/api/paddler/transfer-paddler-to-new-department`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}
export const createKudaAccount = async (data) => {
  const url = `/api/kuda/create-account-for-paddler`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getAllProductUsers = async () => {
  const url = `/api/products/get-all-product-users`
  return callEndpoint({
    url
  })
}
export const getAllDept = async () => {
  const url = `/api/paddler-department/get-all-department`
  return callEndpoint({
    url,
  })
}
export const getAllUnits = async () => {
  const url = `/api/paddler/unit/all`
  return callEndpoint({
    url
  })
}
export const getAllPaymentLogs = async (data) => {
  const url = `/api/payment-log/all`
  return callEndpoint({
    method:POST,url,data
  })
}
export const updateUnit = async (data) => {
  const url = `/api/paddler/unit/update`
  return callEndpoint({
    method: PATCH,
    data,
    url
  })
}

export const getFleetById = async (data) => {
  const url = `/api/fleet/get-fleet-by-id/${data.transportCompanyId}/${data.fleetId}`
  return callEndpoint({
    url
  })
}
export const getCompanyTerminalStat = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-terminal-stat/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getTerminalBookingsByDate = async (data) => {
  const url = `/api/booking/transport-company-terminal-booking-by-date/${data.transportCompanyId}/${data.date}`
  return callEndpoint({
    url
  })
}

export const getCompanyBookingsPerDay = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-bookings-per-day/${transportCompanyId}`
  return callEndpoint({
    url
  })
}
export const getCompanyTripsPerDay = async (transportCompanyId) => {
  const url = `/api/trip/get-total-trips-per-day/${transportCompanyId}`
  return callEndpoint({
    url
  })
}
export const getCompanyRevenuePerDay = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-revenue-per-day/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const addBookingAdmin = async (data) => {
  const url = `/api/booking-admin/new/${data.transportCompanyId}/${data.terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const getCompanyBookingAdmins = async (id) => {
  const url = `/api/booking-admin/get-all/${id}`;
  return callEndpoint({
    url
  });
};

export const getBookingAdminById = async (data) => {
  const url = `/api/booking-admin/get/${data.transportCompanyId}/${data.bookingAdminId}`;
  return callEndpoint({
    url
  });
};

export const updateBookingAdminById = async (data) => {
  const url = `/api/booking-admin/update/${data.transportCompanyId}/${data.terminalId}/${data.bookingAdminId}`;
  return callEndpoint({
    method: PATCH,
    url,
    data
  });
};

export const deleteBookingAdmin = async (data) => {
  const url = `/api/booking-admin/delete/${data.transportCompanyId}/${data.bookingAdminId}`;
  return callEndpoint({
    method: DELETE,
    url
  });
};

export const addPilot = async (data) => {
  const url = `/api/pilot/new-pilot/${data.transportCompanyId}/${data.terminalId}`;
  return callEndpoint({
    method: POST,
    url,
    data
  });
};

export const getAllCompanyPilot = async (id) => {
  const url = `/api/pilot/get-all-of-transport-company/${id}`
  return callEndpoint({
    url
  })
}

export const assignFleetToPilot = async (data) => {
  const url = `/api/pilot/assign-to-fleet/${data.transportCompanyId}/${data.terminalId}/${data.pilotId}/${data.fleetId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const getPilotById = async (data) => {
  const url = `/api/pilot/get/${data.transportCompanyId}/${data.pilotId}`
  return callEndpoint({
    url
  })
}

export const updatePilotById = async (data) => {
  const url = `/api/pilot/update/${data.transportCompanyId}/${data.terminalId}/${data.pilotId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const deletePilotById = async (data) => {
  const url = `/api/pilot/delete/${data.transportCompanyId}/${data.pilotId}`
  return callEndpoint({
    method: DELETE,
    url
  })
}

export const addTrip = async (data) => {
  const url = `/api/trip/new-trip/${data.transportCompanyId}/${data.routeId}`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllTransportCompanyTrips = async (id) => {
  const url = `/api/trip/get-all/${id}`
  return callEndpoint({
    url
  })
}

export const assignFleetToTrip = async (data) => {
  const url = `/api/trip/give-fleet-trip/${data.transportCompanyId}/${data.terminalId}/${data.fleetId}/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const getAllPendingTrips = async (transportCompanyId) => {
  const url = `/api/trip/pending-trip/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const cancelTrip = async (data) => {
  const url = `/api/trip/cancel-trip/${data.transportCompanyId}/${data.terminalId}/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const tripCompleted = async (data) => {
  const url = `/api/trip/completed-trip/${data.transportCompanyId}/${data.newTerminalId}/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const deleteTrip = async (data) => {
  const url = `/api/trip/delete/${data.transportCompanyId}/${data.tripId}`
  return callEndpoint({
    method: DELETE,
    url
  })
}

export const addBookingCode = async (data) => {
  const url = `/api/booking/booking-code/add`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getPassengerDetail = async (bookingCode) => {
  const url = `/api/booking/booking-code/get-traveller-details-by-code/${bookingCode}`
  return callEndpoint({
    url
  })
}

export const verifyPassengerDetailExist = async (detail) => {
  const url = `/api/booking/booking-code/verify-traveller-details-exist/${detail}`
  return callEndpoint({
    url
  })
}
export const getBookingCodeByPhoneNumber = async (phoneNumber) => {
  const url = `/api/booking/booking-code/get-booking-code-by-phone-number/${phoneNumber}`
  return callEndpoint({
    url
  })
}

export const sendOtpToPassenger = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/send`
  return axios.post(url, data)
}

export const verifyPassengerOtp = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/verify`
  return axios.post(url, data)
}

export const addBooking = async (data) => {
  const url = `/api/booking/add-booking/${data.tripId}`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllBookingsByPageSize = async (data) => {
  const url = `/api/booking/get-all`
  return callEndpoint({
    method:POST,url,data
  })
}

export const getTransportCompanyRevenue = async (transportCompanyId) => {
  const url = `/api/booking/transport-company-revenue/${transportCompanyId}`
  return callEndpoint({
    url
  })
}

export const getAllTransportCompanies = async () => {
  const url = `/api/transport-company/get-all`
  return callEndpoint({
    url
  })
}



export const verifyTransportCompany = async (transportCompanyId) => {
  const url = `/api/transport-company/verify-company/${transportCompanyId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const getAllWaterTransportOperators = async () => {
  const url = `/api/water-transport-operator/get-all`
  return callEndpoint({
    url
  })
}

export const verifyWaterTransportOperator = async (data) => {
  const url = `/api/water-transport-operator/verify-operator`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const getAllCorporateAgents = async () => {
  const url = `/api/corporate-agent/get-all`
  return callEndpoint({
    url
  })
}

export const getAllDevelopers = async () => {
  const url = `/api/developer/get-all`
  return callEndpoint({
    url
  })
}

export const subscribeToRibsCompanies = async (data) => {
  const url = `/api/v1/developer/access-transport-company/subscribe-to-transport-companies`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const unsubscribeFromRibsCompanies = async (data) => {
  const url = `/api/v1/developer/access-transport-company/unsubscribe-to-transport-companies`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const activateDeveloper = async (developerId) => {
  const url = `/api/developer/activate/${developerId}`
  return callEndpoint({
    method: POST,
    url
  })
}

export const verifyCorporateAgentById = async (corporateAgentId) => {
  const url = `/api/corporate-agent/verify-corporate-agent-by-id/${corporateAgentId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}


export const getAllMobileAgents = async () => {
  const url = "/api/mobile-agent/get-all"
  return callEndpoint({
    url
  })
}

export const verifyMobileAgentById = async (mobileAgentId) => {
  const url = `/api/mobile-agent/activate/${mobileAgentId}`
  return callEndpoint({
    method: PATCH,
    url
  })
}

export const getAllCorporateOrganization = async () => {
  const url = '/api/corporate-organization/get-all'
  return callEndpoint({
    url
  })
}

export const verifyCorporateOrganization = async (data) => {
  const url = '/api/corporate-organization/verify'
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const getAllPaymentLog = async () => {
  const url = `/api/payment-log/all`
  return callEndpoint({
    url
  })
}

export const AddPosRequest = async (data) => {
  const url = `/api/pos-request/add-new-pos-request`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getPosRequestById = async (data) => {
  const url = `/api/pos-request/get-pos-request-by-id`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllPosProvider = async () => {
  const url = `/api/partner/pos-provider/get-all`
  return callEndpoint({
    url
  })
}

export const getAllPosRequest = async () => {
  const url = `/api/pos-request/get-all-pos-request`
  return callEndpoint({
    url
  })
}

export const approvePosRequest = async (data) => {
  const url = `/api/pos-request/approve-pos-request`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}


export const waitlist = async () => {
  const url = `https://roadpadi-waiting-list.herokuapp.com/api/subscriber/get-all`
  return axios.get(url)
}

export const createBlogPost = async (data) => {
  const url = `/api/blog-post/create`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllBlogPost = async () => {
  const url = `/api/blog-post/get-all`
  return callEndpoint({
    url
  })
}

export const toggleBlogPostStatus = async (data) => {
  const url = `/api/blog-post/update-blog-post-status`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const deletePost = async (data) => {
  const url = `/api/blog-post/delete/${data.id}`
  return callEndpoint({
    method: DELETE,
    url
  })
}

export const updateBlogPost = async (data) => {
  const url = `/api/blog-post/update-blog-post`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const createDiscount = async (data)=>{
  const url = `/api/promotion-bd/discount/create`
  return callEndpoint({method:POST,url,data})
}
export const allDiscounts = async ()=>{
  const url = `/api/promotion-bd/discount/all`
  return callEndpoint({url})
}
export const deactivateDiscount = async (data)=>{
  const url = `/api/promotion-bd/discount/update-status`
  return callEndpoint({method:PATCH,url,data})
}

export const getWalletByUserEmail = async (email) => {
  const url = `/api/wallet/get-wallet-by-user-email/${email}`
  return callEndpoint({
    url
  })
}

export const getPaystackAccountDetails = async (email) => {
  const url = `/api/paystack/get-account-by-user-email/${email}`
  return callEndpoint({
    url
  })
}

export const verifyPaystackPayment = async (userId, reference, data) => {
  const url = `/api/paystack/verify-payment/${userId}/${reference}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}
export const unReserveSeats = async (data) => {
  const url = `/api/customer-trip/un-reserve-seat/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const withdrawFromWallet = async (data) => {
  const url = `/api/wallet/transfer-funds-from-wallet`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const getWalletBalanceByUserEmail = async (email) => {
  const url = `/api/wallet/get-wallet-balance/${email}`
  return callEndpoint({
    url
  })
}

export const getAllBookings = async (data)=>{
  const url = "/api/booking/get-all"
  return callEndpoint({method:POST,url,data})
}

export const getAllInsuranceProvider = async()=>{
  const url = `/api/insurance-provider/get-all`
  return callEndpoint({url})
}

export const verifyInsuranceProvider = async (data)=>{
  const  url = `/api/insurance-provider/verify`
  return callEndpoint({method:PATCH,url,data})
}

const paystackHeaders = {
  'Content-Type': 'application/json',
  "Authorization": 'Bearer ' + process.env.VUE_APP_PAYSTACK_LIVE_SECRET_KEY

};

export const makePayment = async (data) => {
  const url = `https://api.paystack.co/transaction/initialize`
  return axios.post(url, data, {
    headers: paystackHeaders
  })
}

export const createNewsLetter = async (data) => {
  const url = `/api/newsletter/add`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllSearchQuery = async (data) =>{
  const url = `/api/search-query/get-all-query`
  return callEndpoint({method: POST,url,data})
}

export const  upgradeKudaAccount = async (data) => {
  const url = `/api/wallet/upgrade-user-wallet`
  return callEndpoint({method: PATCH, url, data})
}
export const getSearchQueryByDate = async (data) =>{
  const url = `/api/search-query/get-all-query-within-date-range`
  return callEndpoint({method:POST,url,data})
}

export  const getLastTwelveMonthBookingStat = async() =>{
  const url = `/api/booking-bd/get-last-twelve-month-booking-stat`
  return callEndpoint({method:POST,url})
}
export  const getLastSixMonthBookingStat = async() =>{
  const url = `/api/booking-bd/get-last-six-month-booking-stat`
  return callEndpoint({method:POST,url})
}

export const getGeneralStat = async () =>{
  const url = `/api/booking-bd/get-general-stat`
  return callEndpoint({method:POST,url})
}

export  const getYearlyBookingStat = async (data) =>{
  const url = `/api/booking-bd/get-yearly-booking-stat`
  return callEndpoint({method:POST,url,data})
}

export  const getMonthlyBookingStat = async(data) =>{
  const url = `/api/booking-bd/get-monthly-booking-stat`
  return callEndpoint({method:POST,url,data})
}

export const deleteBookingByRefId = async (data) =>{
  const  url = `/api/booking-bd/delete-booking-by-ref-id`
  return callEndpoint({method:POST,url,data})
}

export const addNewAdvert = async (data) =>{
  const url = `/api/advert/create-by-nomadian`
  return callEndpoint({method:POST,url,data})
}

export const getAllAdverts = async (data)=>{
  const url = '/api/advert/get-all'
  return callEndpoint({method:POST,url,data})
}

export const updateAdvertStatus = async (data) =>{
  const url = `/api/advert/update-status`
  return callEndpoint({method:PATCH,url,data})
}

export const  getBookingStatByDateRange = async (data) =>{
  const url = `/api/booking/get-booking-stat-by-date-range`
  return callEndpoint({method:POST,url,data})
}
 export const getBookingCounts = async ()=>{
  const url = `/api/booking/get-all-booking-count`
   return callEndpoint({method:POST,url})
 }

 export const getGeneralLogs = async (data) =>{
  const url = `/api/general-log/get-all`
   return callEndpoint({method:POST,url,data})
}
export const getGeneralLogsStat = async (data) =>{
  const url = `/api/general-log/log-stat`
  return callEndpoint({method:POST,url,data})
}

export {
  paystackAxios
};